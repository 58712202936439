
export const URLs = {
    categories: 'categories',
    products: 'product',
    users: 'auth',
    cart: 'cart',
    order: 'orders',
    payment: 'rzp_payment'

}

